<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-select
          v-model="vm.primaryLanguage"
          :items="primaryLanguage"
          label="Primary language"
          v-validate="'required'"
          data-vv-as="Primary Language"
          name="primaryLanguage"
          :error-messages="errors.collect('primaryLanguage')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.communicationMethod"
          :items="communicationMethod"
          label="Preferred communication method"
          v-validate="'required'"
          data-vv-as="Communication method"
          name="communicationMethod"
          :error-messages="errors.collect('communicationMethod')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.interpreterServices"
          :items="interpreterServices"
          label="Interpreter services"
          v-validate="'required'"
          data-vv-as="Interpreter services"
          name="interpreterServices"
          :error-messages="errors.collect('interpreterServices')"
        ></v-select>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "consent-form",
  model: {},
  data: () => ({
    vm: {},
    form: {},
    communicationMethod: [
      "Spoken language",
      "Sign language",
      "Communication board or device",
      "Other non-spoken communication",
      "Not known"
    ],
    interpreterServices: ["Not Applicable", "Sometimes", "Always Needed"],
    primaryLanguage: [
      "English",
      "Arabic",
      "Bengali",
      "Chinese",
      "French",
      "German",
      "Hindi",
      "Japanese",
      "Javanese",
      "Korean",
      "Lahnda",
      "Marathi",
      "Portuguese",
      "Russian",
      "Spanish",
      "Tamil",
      "Telugu",
      "Turkish",
      "Urdu",
      "Vietnamese"
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.communication == undefined
          ? {}
          : this.$store.getters.currentCase.communication;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.communication = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

